import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs, FreeMode } from 'swiper';
// import { PhotoSwipeLightbox } from 'photoswipe';

import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import scrollSpy from 'simple-scrollspy'

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs, FreeMode]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  // scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  AOS.init();

  // transparent header
  function headerScroll() {
    if ($(window).scrollTop() > 80) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
    if ($(window).scrollTop() > 150) {
      $('.back-to-top').addClass("is-show");
    } else {
      $('.back-to-top').removeClass("is-show");
    }
  }

  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  $('.menu-link').click(function(e) {
    $(this).parent().toggleClass('active').siblings().removeClass('active');
  });

  var indexBanner = new Swiper('#indexBanner', {
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    loop: true,
    speed: 1000,
    pagination: {
      el: ".bn-swiper-pagination",
      clickable: true
    },
  });

  var indexServiceThumbs = new Swiper("#indexServiceThumbs", {
    spaceBetween: 19,
    slidesPerView: 7,
    watchSlidesProgress: true,
    navigation: {
      prevEl: ".index-service-thumb-prev",
      nextEl: ".index-service-thumb-next",
    },
    breakpoints: {
      0: {
        loop: true,
        slidesPerView: 3,
      },
      480: {
        slidesPerView: 4,
      },
      640: {
        loop: false,
        slidesPerView: 7,
      },
    },
  });
  var indexService = new Swiper("#indexService", {
    thumbs: {
      swiper: indexServiceThumbs,
    },
  });


  var videoMenu = new Swiper("#videoMenu", {
    slidesPerView: 6,
    spaceBetween: 6,
    breakpoints: {
      0: {
        slidesPerView: 2.5,
      },
      480: {
        slidesPerView: 3.5,
      },
      640: {
        slidesPerView: 4.5,
      },
      1200: {
        slidesPerView: 6,
        navigation: {
          prevEl: ".video-list-prev",
          nextEl: ".video-list-next",
        },
      },
    },
  });

  var indexPartners = new Swiper("#indexPartners", {
    slidesPerView: 'auto',
    freeMode: true,
    autoplay: {
      delay: 0,
      // pauseOnMouseEnter:true,
      disableOnInteraction: false,
    },
    speed: 5000,
  });

  // 與其他家比較
  const fullmeCompareSlider = sliderElm => {
    return new Swiper(`#${sliderElm.id}`, {
      slidesPerView: 1,
      effect: 'fade',
      autoplay: {
        delay: 1500,
      },
      speed: 1000,
    });
  }

  $('.about-compare-item').find('.swiper').each(function () {
    fullmeCompareSlider(this);
  });

  // 門市
  const StoreSlider = sliderElm => {
    return new Swiper(`#${sliderElm.id}`, {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      navigation: {
        nextEl: `.swiper-btn-next-${sliderElm.id}`,
        prevEl: `.swiper-btn-prev-${sliderElm.id}`
      },
    });
  }
  $('.store-swiper').each(function () {
    StoreSlider(this);
  });

  // dropdown
  $('.dropdown-btn').on('click', function () {
    const id = $(this).attr('id');
    const dropdown_is_active = $('[aria-labelledby=' + id + ']').hasClass("is-active");
    if (dropdown_is_active >= 1) {
      $('[aria-labelledby=' + id + ']').removeClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '',
          'overflow': '',
        })
      }
    } else {
      $(".dropdown-list:not([aria-labelledby=" + id + "]").removeClass('is-active');
      $('[aria-labelledby=' + id + ']').addClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '100vh',
          'overflow': 'hidden',
        })
      }
    }
  });

  $(document).click(function (event) {
    if (!$(event.target).closest('.dropdown-btn').length) {
      if ($('.dropdown-list').is(":visible")) {
        $(".dropdown-list").removeClass('is-active');
        if (window.innerWidth <= 640) {
          $('body').css({
            'height': '',
            'overflow': '',
          })
        }
      }
    }
  });

  // page-menu
  var windowWidth = $(window).width();

  if (windowWidth <= 900 && $('.sidebar-menu').length >= 1) {
    var item = $('.sidebar-menu-item.is-active').width();
    if ($('.sidebar-menu-item').hasClass('is-active')) {
      var item_left_size = $('.sidebar-menu-item.is-active').offset().left;
      if (item_left_size > (windowWidth/2 - item/2)) {
        var scrollTo = item_left_size - windowWidth/2 + item/2;
        $('.sidebar-menu').scrollLeft(scrollTo);
      };
    }
  }

  // 圖片zoom in
  var lightbox = new PhotoSwipeLightbox({
    gallery: '.custom_lightbox',
    children: 'a',
    showHideAnimationType: 'zoom',
    pswpModule: () => import('photoswipe'),
    counter: false
  });

  lightbox.addFilter('itemData', (itemData, index) => {
    const webpSrc = itemData.element.dataset.pswpWebpSrc;
    if (webpSrc) {
      itemData.webpSrc = webpSrc;
    }
    return itemData;
  });


  // use <picture> instead of <img>
  lightbox.on('contentLoad', (e) => {
    const { content, isLazy } = e;

    if (content.data.webpSrc) {
      // prevent to stop the default behavior
      e.preventDefault();

      content.pictureElement = document.createElement('picture');

      const sourceWebp = document.createElement('source');
      sourceWebp.srcset = content.data.webpSrc;
      sourceWebp.type = 'image/webp';

      const sourceJpg = document.createElement('source');
      sourceJpg.srcset = content.data.src;
      sourceJpg.type = 'image/jpeg';

      content.element = document.createElement('img');
      content.element.src = content.data.src;
      content.element.setAttribute('alt', '');
      content.element.className = 'pswp__img';

      content.pictureElement.appendChild(sourceWebp);
      content.pictureElement.appendChild(sourceJpg);
      content.pictureElement.appendChild(content.element);

      content.state = 'loading';

      if (content.element.complete) {
        content.onLoaded();
      } else {
        content.element.onload = () => {
          content.onLoaded();
        };

        content.element.onerror = () => {
          content.onError();
        };
      }
    }
  });

  lightbox.on('contentAppend', (e) => {
    const { content } = e;
    if (content.pictureElement && !content.pictureElement.parentNode) {
      e.preventDefault();
      content.slide.container.appendChild(content.pictureElement);
    }
  });

  lightbox.on('contentRemove', (e) => {
    const { content } = e;
    if (content.pictureElement && content.pictureElement.parentNode) {
      e.preventDefault();
      content.pictureElement.remove();
    }
  });

  lightbox.init();

  //scrollSpy
  const scrollspyOptions = {
    sectionClass: '.scrollspy',
    menuActiveTarget: '.scrollspy-link',
    offset: 150,
  }
  scrollSpy('.scroll-container', scrollspyOptions)
});
